import { endpoints } from '../../endpoints';
import { WidgetAttributes, WidgetModel, WidgetId, FileStorageEntryId } from '../../types/models';
import { api } from '../../utils';

interface WidgetAttributesPatch extends Omit<WidgetAttributes, 'fileStorageImage'> {
  fileStorageImage?: FileStorageEntryId | null;
}

export type UpdateWidgetParams = { widgetId: WidgetId; attributes: Partial<WidgetAttributes> };
export interface UpdateWidgetPathParams extends Omit<UpdateWidgetParams, 'attributes'> {
  attributes: Partial<WidgetAttributesPatch>;
}

export const updateWidget = <T extends WidgetModel>({ widgetId, attributes }: UpdateWidgetPathParams) =>
  api.patch<T>({
    url: endpoints.widgets.widgetId(widgetId),
    data: { attributes },
  });
