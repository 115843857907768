import { currentProfileStore } from '../providers/current-profile';

export const pagesEndpoints = {
  colors: () => `/pages/colors/`,
  defaultPopulate: () => '/pages/default/populate/',
  defaultResetLayoutItems: () => '/pages/default/reset_layout_items/',
  image: () => `/pages/v4/images/`,
  layouts: () => `/pages/layouts/`,
  usersCurrentUserIdPopulate: () => `/pages/users/${currentProfileStore.getState()?.id}/populate/`, // populate
  usersCurrentUserIdPopulatedLayout: (id: number) =>
    `/pages/users/${currentProfileStore.getState()?.id}/populated_layout/${id}/`, // populatedLayout
  usersCurrentUserIdResetLayoutItems: () =>
    `/pages/users/${currentProfileStore.getState()?.id}/reset_layout_items/`, // resetLayoutItems
};
